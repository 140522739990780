module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fumetteria Centro del Mondo","short_name":"Fumetteria Centro del Mondo","start_url":"/","background_color":"#ffffff","theme_color":"#f59e2e","display":"browser","icon":"src/images/icon.png","icons":[{"src":"/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"},{"src":"/images/icon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5a0f6673309962ddfc291ef46367537"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
